import {InMemoryCache} from '@apollo/client';

import {productsPolicy as products} from './products';
import {leadPolicy as lead} from './lead';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        lead,
        products,
      },
    },
  },
});
