import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export const NavBarWrapper = styled(Flex)(
  mediaQuery({
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    height: 70,
    backgroundColor: darkBlue,
  }),
);
