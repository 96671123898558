import {makeVar} from '@apollo/client';

export const initialLead = {
  zipCode: '',
  firstMortgageBalance: '',
  creditScore: '',
  homeValue: '',
  monthlyDebt: '',
  monthlyIncome: '',
  afterRenovationValue: '',
  loanAmount: '',
  propertyUseType: null,
};

// Reactive variables
export const leadVar = makeVar(initialLead);

// Setter
export const setLead = (lead) => {
  leadVar({...leadVar(), ...lead});
};

export const resetLead = () => {
  leadVar({});
};

// Apollo field policy
export const leadPolicy = {
  read() {
    return leadVar();
  },
};

export default {
  leadPolicy,
  setLead,
};
