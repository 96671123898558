import React from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {PencilIcon, Button} from '@renofi/components';

const PencilButton = ({onClick}) => {
  return (
    <Button
      onClick={onClick}
      transparent
      alignItems="center"
      justifyContent="center">
      <Box css={{transform: 'scale(1.4)'}}>
        <PencilIcon />
      </Box>
    </Button>
  );
};

PencilButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PencilButton;
