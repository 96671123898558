import {useState} from 'react';

import {Flex, Text, Link} from 'rebass';

import {ModalFooter, ModalHeader} from '@renofi/components/src/Modal';
import {Button, Checkbox, CheckboxLabel, Modal} from '@renofi/components';
import {useLocalStorage} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

const WelcomeModal = ({show, onClose}) => {
  const [storage, setStorage] = useLocalStorage('guidelines');
  const [checked, setChecked] = useState(storage);

  function onUnderstand() {
    sendEvent('MLO/Welcome-Modal-Accepted', {saved: checked});
    onClose();
    setStorage(checked);
  }

  return (
    <Modal hideCloseButton light show={show} onClose={onClose}>
      <ModalHeader>
        <Text>RenoFi Product Guidelines</Text>
      </ModalHeader>
      <Text>
        If your borrower has experienced any of the following, please contact
        your Account Executive to discuss eligibility:
        <ul>
          <li>Bankruptcy</li>
          <li>Foreclosure</li>
          <li>Short Sale</li>
          <li>Deed-In-Lieu</li>
          <li>Forbearance</li>
          <li>Modification</li>
        </ul>
        RenoFi does not offer products based on After-Renovation Value on
        Non-Owner Occupied properties. We do provide other solutions for these
        properties. Please contact your Account Executive to discuss.
        <Text mt={36}>
          Debt-to-Income Ratio:
          <ul>
            <li>
              43% (Exceptions possible up to 55%) -{' '}
              <Link
                href="https://assets.renofi.com/rmpp/RenoFi%20Marketing%20Partnership%20Program%20Guide.pdf"
                rel="noreferrer"
                css={{
                  textDecoration: 'none',
                  fontSize: 16,
                }}
                target="_blank">
                [Read more]
              </Link>
            </li>
          </ul>
        </Text>
      </Text>

      <ModalFooter>
        <Flex
          minWidth={32}
          onClick={() => setChecked(!checked)}
          css={{cursor: 'pointer'}}>
          <Checkbox id="do-not-show" checked={checked} />
          <CheckboxLabel fontSize={[15, 16]} ml={10}>
            Don’t show this on startup again
          </CheckboxLabel>
        </Flex>

        <Button
          maxWidth={[130, 160]}
          ml={24}
          xSmall
          internal
          onClick={onUnderstand}>
          I understand
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WelcomeModal;
