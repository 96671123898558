import React, {useEffect, useState} from 'react';

import {TitleBar} from 'components';

import {useGetLicences} from '@renofi/api';
import {Alert, Loader, Toggle} from '@renofi/components';

import {Container} from '../App';

const RmppEligibleStates = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [states, setStates] = useState([]);
  const getLicenses = useGetLicences();

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        setLoading(true);

        const {
          data: {licenses},
        } = await getLicenses();

        const mappedStates = licenses.map(({state}) => state);
        const uniqueStates = Array.from(new Set(mappedStates));

        setStates(uniqueStates);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchLicenses();
  }, []);

  return (
    <>
      <TitleBar title="RMPP Eligible Property States"></TitleBar>

      <Container>
        <Toggle show={error}>
          <Alert>Failed to load states</Alert>
        </Toggle>

        <Toggle show={loading}>
          <Loader />
        </Toggle>

        <ul>
          {states.map((state) => (
            <li key={state}>{state}</li>
          ))}
        </ul>
      </Container>
    </>
  );
};

export default RmppEligibleStates;
