import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const ModalFooterWrapper = styled(Flex)(({css}) =>
  mediaQuery({
    position: ['fixed', 'inherit'],
    justifyContent: 'space-between',
    minHeight: 70,
    margin: '36px -20px -20px -20px',
    bottom: [20, 'none'],
    padding: '0 20px',
    width: ['100%', 'auto'],
    alignItems: 'center',
    background: '#F8F8F8',
    ...css,
  }),
);
