import styled from '@emotion/styled/macro';
import {Text as TextComponent} from 'rebass';

export const MainTitle = styled(TextComponent)({
  marginBottom: 15,
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: '24px',
});

export const Text = styled(TextComponent)(
  {
    lineHeight: '23px',
    marginBottom: 20,
    fontSize: 17,
  },
  ({css}) => css,
);

export const List = styled.ol({
  marginTop: 0,
  paddingLeft: 20,
});

export const ListItem = styled.li({
  paddingBottom: 15,
  fontSize: 17,
  paddingLeft: 10,
});

export const Small = styled.span({
  marginTop: 2,
  display: 'block',
  color: '#888A8E',
  fontSize: 15,
  lineHeight: '18px',
});
