import {setProducts} from '../cache';

const map = {
  full_upfront: 'Full amount available at closing',
  line_of_credit: 'Line of credit, full amount available to draw at closing',
  draws_inspection: 'Draws and inspections',
};

export default function ({eligibleProducts}) {
  const products =
    eligibleProducts?.map(({product, ...otherValues}) => {
      return {
        ...product,
        ratesString: `${product.rates?.join('% / ')}%`,
        cashDisbursementType:
          map[product.cashDisbursementType] || product.cashDisbursementType,
        ...otherValues,
      };
    }) || [];
  setProducts(products);
  return products;
}
