import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const TitleBarWrapper = styled(Flex)(
  mediaQuery({
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    position: 'relative',
    alignItems: 'center',
    fontWeight: 400,
    width: '100%',
    height: 70,
    padding: ['0 16px', 'none'],
  }),
);
