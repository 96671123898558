import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {PanelHeaderWrapper} from './styled';

const PanelHeader = ({children, action, icon, middle, css}) => {
  const Icon = icon;
  return (
    <PanelHeaderWrapper middle={middle} css={css}>
      {icon ? (
        <Box mr={12} mt="2px">
          <Icon />
        </Box>
      ) : null}
      {children}
      {action}
    </PanelHeaderWrapper>
  );
};

PanelHeader.propTypes = {
  children: PropTypes.node,
  action: PropTypes.node,
};

export default PanelHeader;
