import PropTypes from 'prop-types';

import {PanelBodyWrapper} from './styled';

const PanelBody = ({children, css}) => {
  return <PanelBodyWrapper css={css}>{children}</PanelBodyWrapper>;
};

PanelBody.propTypes = {
  children: PropTypes.node,
};

export default PanelBody;
