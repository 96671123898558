import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const PanelBodyWrapper = styled(Flex)(({css}) =>
  mediaQuery({
    width: '100%',
    padding: 16,
    ...css,
  }),
);
