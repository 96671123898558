import {useState} from 'react';

import {orderBy} from 'lodash';

export default function useTableSort(list, callback) {
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState(true);

  function onSort(key) {
    const order = sortKey === key ? !sortOrder : true;
    const sortedData = orderBy(list, key, order ? 'asc' : 'desc');
    setSortKey(key);
    setSortOrder(order);
    callback(sortedData);
  }

  return {onSort, sortKey, sortOrder};
}
