import React, {useEffect, useState} from 'react';

import {PDFDownloadLink} from '@react-pdf/renderer';
import {Flex} from 'rebass';
import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {Button, Th, useTableSort} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';
import {sendEvent} from '@renofi/analytics';
import {formatDate} from '@renofi/utils/src/date';

import {Table, Td, Panel} from '../../components';
import TitleBar from '../../components/TitleBar';
import {Container} from '../../App';
import {
  initialLead,
  calculatorsVar,
  setLead,
  setProducts,
} from '../../api/cache';
import Pdf from '../../Pdf';
import Contact from '../../Contact';

import {ReactComponent as DownloadIcon} from './img/download.svg';

const tableHeaders = [
  {key: 'updatedAt', value: 'Last updated', sort: true, width: 120},
  {key: 'nickname', value: 'Nickname', sort: true},
  {
    key: 'firstMortgageBalance',
    value: ' First Mortgage Balance',
    width: 180,
    sort: true,
  },
  {key: 'loanAmount', value: 'Renovation amount', sort: true},
  {key: 'afterRenovationValue', value: 'ARV', sort: true},
  {key: 'rate', value: 'Rate as low as', sort: true},
  {key: 'firstPayment', value: 'Est. monthly payment', sort: true},
  {value: 'Download', width: 111},
];

const CalculatorsList = () => {
  const calculators = useReactiveVar(calculatorsVar);
  const [state, setState] = useState(calculators);
  const {onSort, sortKey, sortOrder} = useTableSort(state, setState);
  const history = useHistory();

  useEffect(() => {
    setState(calculators);
  }, [calculators]);

  useEffect(() => {
    if (!calculators || calculators.length < 1) {
      return onNewCalculator();
    }
    sendEvent('MLO/Calculators-Page', {size: calculators.length});
  }, []);

  function onNewCalculator() {
    sendEvent('MLO/New-Calculator-Button-Click');
    setLead(initialLead);
    setProducts([]);
    history.push('/calculators/new');
  }

  function onOpenCalculator(lead, id) {
    sendEvent('MLO/Existing-Calculator-Row-Click');
    history.push(`/calculators/${id}`);
  }

  function onDownload(e) {
    e.stopPropagation();
    sendEvent('MLO/Existing-Calculator-Row-Save-PDF-Click');
  }

  return (
    <>
      <TitleBar title="Calculators">
        <Button primary xSmall onClick={onNewCalculator}>
          Create new calculator
        </Button>
      </TitleBar>

      <Container>
        <Flex flexDirection="column" width={1}>
          <Panel mt={[0, 33]} noBorder>
            <Table clickable id="calculators-table" responsive horizontal bold>
              <thead>
                <tr>
                  {tableHeaders.map(({key, value, width, sort}) => (
                    <Th
                      css={{background: 'white', paddingRight: 30}}
                      data-testid={key}
                      key={key || value}
                      width={width}
                      sortOrder={sortOrder}
                      isSorted={sortKey === key}
                      onClick={sort ? () => onSort(key) : undefined}>
                      {value}
                    </Th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {state?.map(
                  ({lead, eligibleProducts, id, updatedAt, nickname}) => (
                    <tr key={id} onClick={() => onOpenCalculator(lead, id)}>
                      <Td>
                        {formatDate(new Date(updatedAt), {numeric: true})}
                      </Td>
                      <Td>{nickname}</Td>
                      <Td>${toCurrency(lead.firstMortgageBalance)}</Td>
                      <Td>${toCurrency(lead.loanAmount)}</Td>
                      <Td>${toCurrency(lead.afterRenovationValue)}</Td>
                      <Td>{eligibleProducts[0]?.rates[0]}%</Td>
                      <Td>
                        $
                        {toCurrency(
                          eligibleProducts[0]?.calculatedMonthlyPayment,
                        )}
                      </Td>
                      <Td css={{textAlign: 'center'}}>
                        <PDFDownloadLink
                          document={
                            <Pdf
                              nickname={nickname}
                              lead={lead}
                              eligibleProducts={eligibleProducts}
                            />
                          }
                          fileName="RenoFi - Eligible Products.pdf"
                          onClick={onDownload}>
                          <DownloadIcon />
                        </PDFDownloadLink>
                      </Td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </Panel>
          <Contact />
        </Flex>
      </Container>
    </>
  );
};

export default CalculatorsList;
