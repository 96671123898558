import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {PanelDark} from '@renofi/components';
import {EmailPanel} from '@renofi/modules';

import Card from '../../Card';
import Button from '../../Button';
import EmailIcon from '../../img/email-icon.svg';
import MobileIcon from '../../img/mobile-icon.svg';

const Modal = ({onScheduleClick}) => {
  const [showEmail, setShowEmail] = useState(false);

  if (showEmail) {
    return (
      <EmailPanel
        width={['100%', 'auto']}
        shortName="Charles"
        showCard={false}
        email="scenarios@renofi.com"
        css={{
          position: 'absolute',
          bottom: `calc(100% + 20px)`,
          right: 15,
          width: 'calc(100% - 30px)',
        }}
      />
    );
  }

  return (
    <PanelDark
      css={{
        position: 'absolute',
        bottom: `calc(100% + 20px)`,
        right: 15,
        width: 'calc(100% - 30px)',
      }}
      title="Got questions?">
      <Card />
      <Flex flexDirection="column">
        <Button
          onClick={() => setShowEmail(true)}
          icon={EmailIcon}
          text="Get in touch by email."
        />

        <Button
          icon={MobileIcon}
          text="Schedule a time for a phone call at a later date."
          onClick={onScheduleClick}
        />
      </Flex>
    </PanelDark>
  );
};

Modal.propTypes = {
  onScheduleClick: PropTypes.func,
};

export default Modal;
