import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {Td} from '@renofi/components';

export default styled(Td)(
  mediaQuery({
    fontWeight: 300,
  }),
);
