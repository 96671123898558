import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, red} from '@renofi/utils/src/colors';

export const Container = styled(Flex)(({css}) =>
  mediaQuery({
    width: '100%',
    maxWidth: ['100%', 1140],
    margin: '0 auto',
    ...css,
  }),
);

export const GuidelineLink = styled(Flex)(({selected}) =>
  mediaQuery({
    height: 70,
    width: 'auto',
    color: 'white',
    fontSize: [13, 14],
    opacity: selected ? 1 : 0.5,
    textTransform: 'uppercase',
    alignItems: 'center',
    margin: ['0 12px', '0 24px'],
    cursor: 'pointer',
    borderBottom: `4px solid ${selected ? red : darkBlue}`,
    ':hover': {
      opacity: 1,
    },
  }),
);
