import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {
  Alert,
  Button,
  ButtonSelect,
  CurrencyField,
  Label,
  TextField,
  Toggle,
} from '@renofi/components';

import {Tooltip} from '../../Tooltips';

import {Grid, Link} from './styled';

const PropertyDetails = ({onChange, onSubmit, formData, errors, loading}) => {
  const isUnsupportedType =
    formData.propertyUseType === 'secondary_residence' ||
    formData.propertyUseType === 'investment_property';
  return (
    <Box width={1}>
      <Grid>
        <Box>
          <Label error={errors.zipCode} small htmlFor="zipCode">
            Zip Code
          </Label>
          <TextField
            limit={5}
            error={errors.zipCode}
            small
            icon="location"
            value={formData.zipCode}
            onChange={(value, err) => onChange('zipCode', value, err)}
            id="zipCode"
            name="zipCode"
            type="text"
            required
            inputmode="numeric"
            pattern={'[0-9]*'}
          />
        </Box>

        <Box>
          <Label
            error={errors.firstMortgageBalance}
            small
            htmlFor="firstMortgageBalance">
            First Mortgage Balance
          </Label>
          <CurrencyField
            small
            icon
            error={errors.firstMortgageBalance}
            value={formData.firstMortgageBalance}
            onChange={(value, err) =>
              onChange('firstMortgageBalance', value, err)
            }
            id="firstMortgageBalance"
            name="firstMortgageBalance"
            required
          />
        </Box>

        <Box>
          <Label error={errors.creditScore} small htmlFor="creditScore">
            Estimated Credit Score
          </Label>
          <TextField
            small
            numbers
            error={errors.creditScore}
            value={formData.creditScore}
            onChange={(value, err) => onChange('creditScore', value, err)}
            id="creditScore"
            name="creditScore"
            type="text"
            required
            inputmode="numeric"
            pattern={'[0-9]*'}
          />
        </Box>

        <Box>
          <Label error={errors.homeValue} small htmlFor="homeValue">
            Purchase Price/Current Value
          </Label>
          <CurrencyField
            small
            icon
            error={errors.homeValue}
            value={formData.homeValue}
            onChange={(value, err) => onChange('homeValue', value, err)}
            id="homeValue"
            name="homeValue"
            type="text"
            required
          />
        </Box>

        <Box>
          <Label error={errors.monthlyDebt} small htmlFor="monthlyDebt">
            Total Monthly Debts
          </Label>
          <CurrencyField
            topMessage={
              <Tooltip tooltipProps={{width: 290}} id="Total Monthly Debts" />
            }
            small
            icon
            error={errors.monthlyDebt}
            value={formData.monthlyDebt}
            onChange={(value, err) => onChange('monthlyDebt', value, err)}
            id="monthlyDebt"
            name="monthlyDebt"
            required
          />
        </Box>

        <Box>
          <Label error={errors.monthlyIncome} small htmlFor="monthlyIncome">
            Gross Monthly Income
          </Label>
          <CurrencyField
            small
            icon
            error={errors.monthlyIncome}
            value={formData.monthlyIncome}
            onChange={(value, err) => onChange('monthlyIncome', value, err)}
            id="monthlyIncome"
            name="monthlyIncome"
            required
          />
        </Box>

        <Box>
          <Label
            error={errors.afterRenovationValue}
            small
            htmlFor="afterRenovationValue">
            After Renovation Home Value
          </Label>
          <CurrencyField
            topMessage={
              <Tooltip
                id="After Renovation Home Value"
                tooltipProps={{width: 420}}
              />
            }
            small
            icon
            error={errors.afterRenovationValue}
            value={formData.afterRenovationValue}
            onChange={(value, err) =>
              onChange('afterRenovationValue', value, err)
            }
            id="afterRenovationValue"
            name="afterRenovationValue"
            required
          />
        </Box>

        <Box>
          <Label error={errors.loanAmount} small htmlFor="loanAmount">
            Renovation Loan Amount
          </Label>
          <CurrencyField
            small
            icon
            error={errors.loanAmount}
            value={formData.loanAmount}
            onChange={(value, err) => onChange('loanAmount', value, err)}
            id="loanAmount"
            name="loanAmount"
            required
          />
        </Box>
      </Grid>
      <Box mt={24}>
        <Label error={errors.propertyUseType} small htmlFor="propertyUseType">
          Property Intended Use
        </Label>
        <ButtonSelect
          css={{justifyContent: 'flex-start'}}
          inline
          internal
          small
          error={errors.propertyUseType}
          value={formData.propertyUseType}
          onClick={(value, err) => onChange('propertyUseType', value, err)}
          options={{
            'Primary residence': 'primary_residence',
            'Second home or vacation home': 'secondary_residence',
            'Investment property': 'investment_property',
          }}
        />
      </Box>
      <Toggle show={isUnsupportedType}>
        <Alert icon warning border="none" large mt={[8, 20]}>
          {formData.propertyUseType === 'secondary_residence'
            ? 'Second home or vacation home'
            : 'Investment Properties'}{' '}
          may be eligible for some loan products. Please{' '}
          <Link to="/signup">reach out to your Account Executive</Link> to
          discuss.
        </Alert>
      </Toggle>
      <Button
        css={{width: '100%', paddingRight: [35, 35]}}
        mt={24}
        onClick={onSubmit}
        loading={loading}>
        {loading ? 'Loading' : 'Show eligible products'}
      </Button>{' '}
    </Box>
  );
};

PropertyDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  formData: PropTypes.object,
  loading: PropTypes.bool,
};

export default PropertyDetails;
