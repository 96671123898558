import React from 'react';

import PropTypes from 'prop-types';

import {InfoTooltip} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import AfterRenovationValueTooltip from './AfterRenovationValueTooltip';
import TotalMonthlyDebtTooltip from './TotalMonthlyDebtTooltip';

const tooltipsMap = {
  'Total Monthly Debts': TotalMonthlyDebtTooltip,
  'After Renovation Home Value': AfterRenovationValueTooltip,
};

const Tooltip = ({id, css, tooltipProps, ...props}) => {
  const TooltipContent = tooltipsMap[id];
  const {isMobile} = useScreenSize();
  const place = isMobile ? 'left' : 'top';
  const tipProps = {...tooltipProps, iconWidth: isMobile ? null : 18};

  if (!TooltipContent) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        flexBasis: 20,
        height: 27,
        bottom: 3,
      }}>
      <InfoTooltip id={id} css={css} place={place} {...tipProps}>
        <TooltipContent {...props} />
      </InfoTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  css: PropTypes.object,
};

export default Tooltip;
