import React, {useState} from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {Box, Text} from 'rebass';

import charles from '../img/charles.png';

import Modal from './Modal';
import {Wrapper, Content, Thumb, CloseButton} from './styled';

const ContactMobile = ({onSchedule}) => {
  const [modal, setModal] = useState(false);

  function onClose(e) {
    e.stopPropagation();
    setModal(false);
  }

  return ReactDOM.createPortal(
    <Wrapper
      style={{zIndex: 100, width: '100%', position: 'fixed', bottom: '0px'}}>
      <Content onClick={() => setModal(true)}>
        <Box>
          <Text color="white">Your RenoFi Advisor</Text>

          <Text
            css={{
              fontSize: 14,
              color: '#80CFFF',
              textDecoration: 'underline',
            }}>
            Contact Charles Edington today
          </Text>
        </Box>

        {modal ? <CloseButton onClick={onClose} /> : <Thumb src={charles} />}

        {modal ? <Modal onScheduleClick={onSchedule} /> : null}
      </Content>
    </Wrapper>,
    document.getElementById('root'),
  );
};

ContactMobile.propTypes = {
  onSchedule: PropTypes.func.isRequired,
};

export default ContactMobile;
