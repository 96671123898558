import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {LogoWhiteIcon} from '@renofi/components';

import {Container} from '../../App';

import {NavBarWrapper} from './styled';

const NavigationBar = ({children}) => {
  const pageWidth = 1140;
  const logoWidth = 102;

  return (
    <NavBarWrapper>
      <Container css={{maxWidth: pageWidth + logoWidth * 2}}>
        <Flex alignItems="center" mx={16}>
          <LogoWhiteIcon />
        </Flex>
        {children}
      </Container>
    </NavBarWrapper>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.node,
};

export default NavigationBar;
