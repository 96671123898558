import {useEffect, useState} from 'react';

import {Flex, Box, Text} from 'rebass';
import PropTypes from 'prop-types';

import {Button, Label, Modal, TextField} from '@renofi/components';
import {ModalHeader} from '@renofi/components/src/Modal';
import {sendEvent} from '@renofi/analytics';

const NicknameModal = ({show, onClose, onSave, value = ''}) => {
  const [nickname, setNickname] = useState(value);
  const [error, setError] = useState(null);

  useEffect(() => sendEvent('MLO/Nickname-Modal'), []);

  useEffect(() => {
    setNickname(value);
  }, [value]);

  function onChange(newValue, err) {
    setNickname(newValue);
    setError(err);
  }

  function onSaveClick() {
    sendEvent('MLO/Nickname-Modal-Submit');
    nickname && onSave(nickname);
  }

  return (
    <Modal
      show={show}
      light
      width={360}
      contentCss={{background: '#F8F8F8'}}
      onClose={onClose}>
      <ModalHeader>
        <Text>Save it for later</Text>
      </ModalHeader>

      <Flex flexDirection="column" width={1}>
        <Box>
          <Label error={error} small htmlFor="nickname">
            Nickname
          </Label>
          <TextField
            small
            noSpecialCharacters
            error={error}
            value={nickname}
            onChange={onChange}
            id="nickname"
            name="nickname"
            type="text"
            required
          />
        </Box>

        <Text my={32} fontSize={16}>
          This is an experimental feature that stores your saved calculators on
          this device
        </Text>
        <Button data-testid="save-calculator-button" onClick={onSaveClick}>
          Save calculator
        </Button>
      </Flex>
    </Modal>
  );
};

NicknameModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default NicknameModal;
