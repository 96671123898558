import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const PanelWrapper = styled(Box)(({noBorder}) =>
  mediaQuery({
    width: '100%',
    background: '#FBFBFB',
    borderRadius: 4,
    border: noBorder ? 'none' : 'solid 1px #E5E5E5',
  }),
);
