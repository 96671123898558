import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Image, Text, Flex, Box, Link} from 'rebass';

import {copyToClipboard} from '@renofi/utils';
import {darkBlue, gray} from '@renofi/utils/src/colors';
import {PanelDark, Button} from '@renofi/components';

import rich from './img/pic-circle.png';
import charles from './img/charles.png';
import checkIcon from './img/check.svg';

const EmailPanel = ({css, email, name, shortName, showCard = true}) => {
  const [copied, setCopied] = useState(false);

  function copy() {
    copyToClipboard(email);
    setCopied(true);
  }

  return (
    <PanelDark css={css} title="Get in touch by email">
      {showCard && (
        <Flex pb={20}>
          <Image
            mr={20}
            width={60}
            height={60}
            minWidth={60}
            src={name === 'charles' ? charles : rich}
          />
          <Box pt="8px" css={{textAlign: 'left'}}>
            <Text fontSize={18} color={darkBlue}>
              {name}
            </Text>

            <Text fontSize={14} color={gray}>
              Your dedicated RenoFi Advisor
            </Text>
          </Box>
        </Flex>
      )}

      <Text fontSize={14} color={gray}>
        Email {shortName} at the address below:
      </Text>

      <Link href={`mailto:${email}`} fontSize={14} color={gray}>
        {email}
      </Link>

      <Box width={1} mt="8px">
        <Button onClick={copy} secondary xSmall width="100%">
          {copied ? (
            <Image width={21} height={21} src={checkIcon} />
          ) : (
            'Copy email address to your clipboard'
          )}
        </Button>
      </Box>
    </PanelDark>
  );
};

EmailPanel.propTypes = {
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  email: PropTypes.string,
  name: PropTypes.string,
  shortName: PropTypes.string,
  showCard: PropTypes.bool,
};

export default EmailPanel;
