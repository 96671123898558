import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Grid = styled(Box)(({columns}) =>
  mediaQuery({
    width: '100%',
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: ['100%', columns || '1fr 1fr'],
  }),
);
