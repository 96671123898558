import {useQuery} from '@apollo/client';
import {noop} from 'lodash';

import {ELIGIBLE_PRODUCTS} from '../queries/eligibleProducts';

export default function useEligibleProducts({onCompleted = noop} = {}) {
  const {refetch} = useQuery(ELIGIBLE_PRODUCTS, {skip: true, onCompleted});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
