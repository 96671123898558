import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkGray} from '@renofi/utils/src/colors';

export const PanelHeaderWrapper = styled(Flex)(({middle, css}) =>
  mediaQuery({
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 400,
    width: '100%',
    color: darkGray,
    height: 60,
    background: 'white',
    [`border${middle ? 'Bottom' : 'Top'}LeftRadius`]: 4,
    [`border${middle ? 'Bottom' : 'Top'}RightRadius`]: 4,
    borderBottom: 'solid 1px #E5E5E5',
    borderTop: middle ? 'solid 1px #E5E5E5' : 'none',
    padding: '0 16px',
    ...css,
  }),
);
