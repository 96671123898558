import React from 'react';

import {PanelDark} from '@renofi/components';

const TotalMonthlyDebtTooltip = () => {
  return (
    <PanelDark css={{fontStyle: 'normal'}} title="Total Monthly Debts">
      Including mortgage.
    </PanelDark>
  );
};

export default TotalMonthlyDebtTooltip;
