import React from 'react';

import PropTypes from 'prop-types';
import {Box, Flex} from 'rebass';
import {PDFDownloadLink} from '@react-pdf/renderer';

import {Button} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';

import Pdf from '../../Pdf';

const ActionButtons = ({lead, eligibleProducts, nickname, onSaveClick}) => {
  function onSaveCalculator() {
    sendEvent('MLO/Save-Calculator-Click', lead);
    onSaveClick();
  }

  return (
    <Flex px={16} py={24} flexDirection={['column', 'row']}>
      <Button
        width="100%"
        secondary
        pr={[0, 16]}
        mb={[16, 0]}
        onClick={onSaveCalculator}>
        Save Calculator
      </Button>

      <Box width="100%" pl={[0, 16]}>
        <PDFDownloadLink
          document={
            <Pdf
              nickname={nickname}
              lead={lead}
              eligibleProducts={eligibleProducts}
            />
          }
          fileName="RenoFi - Eligible Products.pdf"
          onClick={() => sendEvent('MLO/Save-PDF-Click')}>
          {() => (
            <Button secondary width="100%">
              Download pdf
            </Button>
          )}
        </PDFDownloadLink>
      </Box>
    </Flex>
  );
};

ActionButtons.propTypes = {
  lead: PropTypes.object,
  eligibleProducts: PropTypes.array,
  onSaveClick: PropTypes.func.isRequired,
};

export default ActionButtons;
