import React, {useState} from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const PencilIcon = ({
  hoverColor = lightGray,
  onClick,
  color = lightGray,
  size = 16,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      onClick={onClick ? onClick : noop}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 10.5625V12.75H3.9375L10.3892 6.29833L8.20167 4.11083L1.75 10.5625ZM12.0808 4.60666C12.3083 4.37916 12.3083 4.01166 12.0808 3.78416L10.7158 2.41916C10.4883 2.19166 10.1208 2.19166 9.89333 2.41916L8.82583 3.48666L11.0133 5.67416L12.0808 4.60666Z"
        fill={hover ? hoverColor : color}
      />
    </svg>
  );
};

export default PencilIcon;
