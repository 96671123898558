import React from 'react';

import {Text} from 'rebass';

import {PanelDark} from '@renofi/components';

const AfterRenovationValueTooltip = () => {
  return (
    <PanelDark css={{fontStyle: 'normal'}} title="After Renovation Home Value">
      <Text mb={16}>
        Typical ROI is 70%, you can roughly estimate the ARV with this formula:
      </Text>
      <strong>
        Estimated Current Home Value + (70% x Cost of Renovations)
      </strong>
    </PanelDark>
  );
};

export default AfterRenovationValueTooltip;
