import styled from '@emotion/styled/macro';
import {Button} from 'rebass';

import CloseIcon from '../img/close-circle.svg';

export const CloseButton = styled(Button)({
  cursor: 'pointer',
  margin: '5px 30px 0 45px',
  width: 60,
  height: 60,
  borderRadius: 100,
  background: `url(${CloseIcon})`,
});
