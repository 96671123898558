import styled from '@emotion/styled/macro';

import {Table} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export default styled(Table)(
  mediaQuery({
    fontSize: 14,
    color: gray,
    minWidth: 1000,
    background: 'white',
  }),
);
