import React from 'react';

import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloClient, ApolloProvider, from, HttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import initPolyfills from '@renofi/utils/src/polyfills';
import {fetchWithRateLimitHandler} from '@renofi/api';

import App from './App';
import {cache} from './api/cache';
import './index.css';
import '@renofi/analytics/src/chilipiper.css';
import {initAnalytics} from './analytics';

initPolyfills();
initAnalytics();

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});
const authLink = setContext((_, {headers}) => {
  return {headers};
});
const client = new ApolloClient({
  cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([authLink, httpLink]),
});

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
);
