import React from 'react';

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

const ModalHeader = ({children, ...props}) => {
  return (
    <Flex
      css={{borderBottom: 'solid 1px #D8D8D8'}}
      minHeight={60}
      mb={36}
      mx={-20}
      px={20}
      mt={-20}
      pt={20}
      fontSize={18}
      fontWeight={500}
      {...props}
      alignItems="flex-start">
      {children}
    </Flex>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.node,
};

export default ModalHeader;
