import {
  calculateMaxLoanFromDtiAndLtv,
  calculateMonthlyPayment,
} from '@renofi/utils';

export function calcMonthlyPayment(lead, product) {
  return calculateMonthlyPayment({
    hasTerms: !product.interestOnly,
    selectedTerm: product.repaymentPeriod,
    loanAmount: lead.loanAmount,
    selectedRate: product.rates[0],
  });
}

export function calcMaxLoan(lead, product) {
  return calculateMaxLoanFromDtiAndLtv({
    maxDti: product.maxDti,
    maxArvCltv: product.maxArvCltv,
    maxPeakCltv: product.maxPeakCltv,
    monthlyDebt: lead.monthlyDebt,
    annualIncomePretax: lead.monthlyIncome * 12,
    exampleRate: product.rates[0],
    maxLoanAmount: product.maxLoanAmount,
    terms: product.repaymentPeriod,
    afterRenovatedValue: lead.afterRenovationValue,
    firstMortgageBalance: lead.firstMortgageBalance,
    secondMortgageBalance: 0,
    homeValue: lead.homeValue,
    productType: product.productType,
  });
}
