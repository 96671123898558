import React from 'react';

import PropTypes from 'prop-types';

import {ModalFooterWrapper} from './styled';

const ModalFooter = ({children, css}) => {
  return <ModalFooterWrapper css={css}>{children}</ModalFooterWrapper>;
};

ModalFooter.propTypes = {
  children: PropTypes.node,
};

export default ModalFooter;
