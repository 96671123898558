import React from 'react';

import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import PencilButton from '../../components/PencilButton';

const PanelTitle = ({isNewCalculator, calculator, onEdit}) => {
  return (
    <>
      {isNewCalculator ? (
        'New calculator'
      ) : (
        <Flex alignItems="center">
          <Box>{calculator?.nickname}</Box>
          <Box ml={10} mt="7px" data-testid="edit-nickname-button">
            <PencilButton onClick={onEdit} />
          </Box>
        </Flex>
      )}
    </>
  );
};

PanelTitle.propTypes = {
  calculator: PropTypes.object,
};

export default PanelTitle;
