import PropTypes from 'prop-types';

import {PanelWrapper} from './styled';

const Panel = ({children, ...props}) => {
  return <PanelWrapper {...props}>{children}</PanelWrapper>;
};

Panel.propTypes = {
  children: PropTypes.node,
};

export default Panel;
