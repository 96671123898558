import PropTypes from 'prop-types';
import {Flex, Text, Box} from 'rebass';
import {useHistory} from 'react-router-dom';

import {Toggle, BackIcon} from '@renofi/components';

import {Container} from '../../App';

import {TitleBarWrapper} from './styled';

const TitleBar = ({title, children, showBackButton}) => {
  const history = useHistory();

  return (
    <TitleBarWrapper>
      <Container>
        <Flex alignItems="center" justifyContent="space-between" width={1}>
          <Flex alignItems="center">
            <Toggle show={showBackButton}>
              <Box
                onClick={() => history.goBack()}
                mr={10}
                height={27}
                css={{cursor: 'pointer'}}>
                <BackIcon />
              </Box>
            </Toggle>
            <Text fontSize={24}>{title}</Text>
          </Flex>
          <Box>{children}</Box>
        </Flex>
      </Container>
    </TitleBarWrapper>
  );
};

TitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  prefix: PropTypes.node,
};

export default TitleBar;
