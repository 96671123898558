import React from 'react';

import PropTypes from 'prop-types';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

import {toCurrency} from '@renofi/utils/src/format';
import {fadeGray} from '@renofi/utils/src/colors';

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
    fontSize: 9,
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    borderLeft: 'none',
    borderBottom: 'none',
  },
  row: {
    fontSize: 9,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #D8D8D8',
  },
  td: {
    display: 'flex',
    padding: 8,
    width: '14.28%',
    height: '100%',
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #D8D8D8',
  },
  th: {
    fontSize: 9,
    padding: 8,
    minHeight: 56,
    width: '14.28%',
    backgroundColor: fadeGray,
    borderLeft: '1px solid #D8D8D8',
  },
});

const PdfTableRow = ({eligibleProducts}) => {
  const products = eligibleProducts?.length ? eligibleProducts : [];

  return (
    <View style={styles.wrapper}>
      <View style={styles.row}>
        <Text style={styles.th}>Product name</Text>
        <Text style={styles.th}>Rate as low as</Text>
        <Text style={styles.th}>Term</Text>
        <Text style={styles.th}>Monthly payment</Text>
        <Text style={styles.th}>Refinancing</Text>
        <Text style={styles.th}>Max loan amount</Text>
        <Text style={styles.th}>Drawing</Text>
      </View>

      {products?.map((product) => {
        return (
          <View style={styles.row} key={product.name}>
            <Text style={styles.td}>{product.name}</Text>
            <Text style={styles.td}>{product.rates[0]}%</Text>
            <Text style={styles.td}>{product.repaymentPeriod} years</Text>
            <Text style={styles.td}>
              ${toCurrency(product.renovationFractionMinMonthlyPayment)}
            </Text>
            <Text style={styles.td}>
              {product.refinanceRequired ? 'Required' : 'Not required'}
            </Text>
            <Text style={styles.td}>
              ${toCurrency(product.calculatedMaxLoanAmount)}
            </Text>
            <Text style={styles.td}>{product.cashDisbursementType}</Text>
          </View>
        );
      })}
    </View>
  );
};

PdfTableRow.propTypes = {
  eligibleProducts: PropTypes.array,
};

export default PdfTableRow;
