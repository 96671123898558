import React from 'react';

import {Image} from 'rebass';
import PropTypes from 'prop-types';

import {Wrapper, Text} from './styled';

const Button = ({text, icon, onClick}) => {
  return (
    <Wrapper onClick={onClick}>
      <Image width={24} height={24} src={icon} />
      <Text>{text}</Text>
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;
