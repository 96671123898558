import React from 'react';

import PropTypes from 'prop-types';
import {View, StyleSheet} from '@react-pdf/renderer';

import PdfTableRow from '../PdfTableRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const PdfTable = ({eligibleProducts, lead}) => (
  <View style={styles.tableContainer}>
    {/*<TableHeader />*/}
    <PdfTableRow eligibleProducts={eligibleProducts} lead={lead} />
    {/*<TableFooter items={data.items} />*/}
  </View>
);

PdfTable.propTypes = {
  eligibleProducts: PropTypes.array,
};

export default PdfTable;
