import {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {toCurrency} from '@renofi/utils/src/format';

import {Th, useTableSort} from '../';

import {Table, Td} from './styled';

const tableHeaders = [
  {key: 'name', value: 'Product name'},
  {key: 'rates', value: 'Rate as low as', width: 120},
  {key: 'repaymentPeriod', value: 'Term'},
  {
    key: 'renovationFractionMinMonthlyPayment',
    value: 'Monthly payment',
    width: 120,
  },
  {key: 'refinanceRequired', value: 'Refinancing'},
  {key: 'maxLoanAmount', value: 'Max loan amount', width: 120},
  {key: 'cashDisbursementType', value: 'Drawing'},
];

const ProductsTable = ({eligibleProducts}) => {
  const [products, setProducts] = useState(eligibleProducts);
  const {onSort, sortKey, sortOrder} = useTableSort(products, setProducts);

  useEffect(() => {
    setProducts(eligibleProducts);
  }, [eligibleProducts]);

  if (eligibleProducts.length <= 0) {
    return null;
  }

  return (
    <Table horizontal id="eligible-products-table" responsive>
      <thead>
        <tr>
          {tableHeaders.map(({key, value, width}) => (
            <Th
              css={{background: 'white', paddingRight: 30}}
              data-testid={key}
              key={key}
              width={width}
              sortOrder={sortOrder}
              isSorted={sortKey === key}
              onClick={() => onSort(key)}>
              {value}
            </Th>
          ))}
        </tr>
      </thead>

      <tbody data-testid="eligible-products-items">
        {products?.map((product, index) => {
          return (
            <tr key={index} data-testid={product.id}>
              <Td color="red">{product.name}</Td>
              <Td>{product.rates[0]}%</Td>
              <Td>{product.repaymentPeriod} years</Td>
              <Td>
                <strong>
                  ${toCurrency(product.renovationFractionMinMonthlyPayment)}
                </strong>
              </Td>
              <Td>{product.refinanceRequired ? 'Required' : 'Not required'}</Td>
              <Td>
                <strong>${toCurrency(product.maxLoanAmountOffered)}</strong>
              </Td>
              <Td>{product.cashDisbursementType}</Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ProductsTable.propTypes = {
  eligibleProducts: PropTypes.array,
  lead: PropTypes.object,
};

export default ProductsTable;
