import {
  Text as PDFText,
  Page as PDFPage,
  View,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

import {gray} from '@renofi/utils/src/colors';

const text = {
  fontSize: 10,
};

const strong = {
  ...text,
  color: 'black',
  fontWeight: 'bold',
};

const logo = {
  width: 90,
  height: 'auto',
  marginBottom: 16,
};

const list = {
  ...text,
  marginBottom: 2,
};

const container = {
  marginBottom: 16,
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
};

const halfPage = {
  width: '50%',
};

const page = {
  fontFamily: 'Averta',
  width: '100%',
  height: '100%',
  border: 'none',
  padding: 24,
  color: gray,
  flexDirection: 'column',
  backgroundColor: 'white',
  justifyContent: 'space-between',
};

const line = {
  width: '100%',
  height: 1,
  borderTopColor: '#F8F8F8',
  borderTopWidth: 2,
  borderTopStyle: 'solid',
  marginTop: 8,
  marginBottom: 32,
};

const footer = {
  backgroundColor: '#F8F8F8',
  padding: 24,
  marginLeft: -24,
  marginRight: -24,
  marginBottom: -24,
  color: '#202020',
};

const card = {
  justifyContent: 'flex-start',
  flexDirection: 'row',
};

const styles = StyleSheet.create({
  page,
  text,
  list,
  strong,
  line,
  container,
  footer,
  halfPage,
  logo,
  card,
});

export const Text = ({children}) => (
  <PDFText style={styles.text}>{children}</PDFText>
);
export const Strong = ({children}) => (
  <PDFText style={styles.strong}>{children}</PDFText>
);
export const Logo = ({children, ...props}) => (
  <Image {...props} style={styles.logo}>
    {children}
  </Image>
);
export const List = ({children}) => (
  <PDFText style={styles.list}>{children}</PDFText>
);
export const Page = ({children}) => (
  <PDFPage style={styles.page}>{children}</PDFPage>
);
export const Footer = ({children}) => (
  <View style={styles.footer}>{children}</View>
);
export const Line = ({children}) => <View style={styles.line}>{children}</View>;
export const Card = ({children}) => <View style={styles.card}>{children}</View>;
export const Container = ({children}) => (
  <View style={styles.container}>{children}</View>
);
export const HalfPage = ({children}) => (
  <View style={styles.halfPage}>{children}</View>
);
