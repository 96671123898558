import {useState, useEffect, useLayoutEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box, Text} from 'rebass';
import PropTypes from 'prop-types';
import {every, isNil, omit} from 'lodash';

import {
  Button,
  Label,
  Modal,
  PhoneField,
  TextField,
  Toggle,
} from '@renofi/components';
import {useLocalStorage, useScreenSize} from '@renofi/utils';
import {ModalFooter, ModalHeader} from '@renofi/components/src/Modal';
import {validateRequired} from '@renofi/utils/src/validate';
import {sendEvent} from '@renofi/analytics';

import {ReactComponent as ChecklistIcon} from './img/checklist.svg';
import {Grid} from './styled';

const initialState = {
  firstName: '',
  lastName: '',
  nmls: '',
  email: '',
  phone: '',
  company: '',
};

const OfficerFormModal = ({show = true, onSubmit}) => {
  const [formData, setFormData] = useLocalStorage('mlo', initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const {isDesktop} = useScreenSize();

  useEffect(() => sendEvent('MLO/MLO-Details-Modal-Open'), []);

  useLayoutEffect(() => {
    if (isFormValid()) {
      onSubmit(formData);
    }
  }, []);

  function onClose() {
    sendEvent('MLO/MLO-Details-Modal-Close');
    history.goBack();
  }

  function onChange(key, value, err) {
    setFormData({...formData, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function isFormValid() {
    return Boolean(
      every(errors, isNil) &&
        formData.firstName &&
        formData.lastName &&
        formData.email &&
        formData.nmls,
    );
  }

  function validateForm() {
    const requiredItems = omit(formData, ['phone', 'company']);
    const requiredErrors = validateRequired(requiredItems);
    setErrors({...requiredErrors, ...errors});
  }

  function onSubmitClick() {
    if (!isFormValid()) {
      return validateForm();
    }

    sendEvent('MLO/MLO-Details-Modal-Submit');
    onSubmit(formData);
  }

  return (
    <Modal
      show={show}
      light
      width={886}
      contentCss={{background: '#F8F8F8'}}
      onClose={onClose}>
      <ModalHeader>
        <Text>Get full access</Text>
      </ModalHeader>

      <Flex flexDirection={['column', 'row']} width={1}>
        <Toggle show={isDesktop}>
          <Box width={260} mx={36}>
            <ChecklistIcon />
          </Box>
        </Toggle>

        <Box width={1}>
          <Text>
            Take the first step towards providing a differentiated solution for
            your consumers and close more loans!
          </Text>

          <h2>Loan Officers Info</h2>

          <Grid>
            <Box>
              <Label error={errors.firstName} small htmlFor="first-name">
                First Name
              </Label>
              <TextField
                small
                noSpecialCharacters
                error={errors.firstName}
                value={formData.firstName}
                onChange={(value, err) => onChange('firstName', value, err)}
                id="first-name"
                name="first-name"
                type="text"
                required
              />
            </Box>

            <Box>
              <Label error={errors.lastName} small htmlFor="last-name">
                Last Name
              </Label>
              <TextField
                noSpecialCharacters
                small
                error={errors.lastName}
                value={formData.lastName}
                onChange={(value, err) => onChange('lastName', value, err)}
                id="last-name"
                name="last-name"
                type="text"
                required
              />
            </Box>

            <Box>
              <Label error={errors.nmls} small htmlFor="nmls">
                NMLS ID
              </Label>
              <TextField
                small
                error={errors.nmls}
                value={formData.nmls}
                onChange={(value, err) => onChange('nmls', value, err)}
                id="nmls"
                name="nmls"
                type="text"
                required
              />
            </Box>

            <Box>
              <Label error={errors.email} small htmlFor="email">
                Email
              </Label>
              <TextField
                email
                small
                error={errors.email}
                value={formData.email}
                onChange={(value, err) => onChange('email', value, err)}
                id="email"
                name="email"
                type="text"
                required
              />
            </Box>

            <Box>
              <Label error={errors.phone} small htmlFor="phone">
                Mobile
              </Label>
              <PhoneField
                small
                topMessage="Optional"
                error={errors.phone}
                value={formData.phone}
                onChange={(value, err) => onChange('phone', value, err)}
                id="phone"
                name="phone"
                type="text"
              />
            </Box>

            <Box>
              <Label error={errors.company} small htmlFor="company">
                Company
              </Label>
              <TextField
                topMessage="Optional"
                small
                error={errors.company}
                value={formData.company}
                onChange={(value, err) => onChange('company', value, err)}
                id="company"
                name="company"
                type="text"
              />
            </Box>
          </Grid>

          <Text my={32} fontSize={14}>
            RenoFi values your privacy. We do not share your details. Read our
            privacy policy.
          </Text>

          <ModalFooter>
            <Flex justifyContent="flex-end" width={1} mb="24px">
              <Button width={['100%', 300]} onClick={onSubmitClick}>
                Submit
              </Button>
            </Flex>
          </ModalFooter>
        </Box>
      </Flex>
    </Modal>
  );
};

OfficerFormModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default OfficerFormModal;
