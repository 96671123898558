import {v4 as uuid} from 'uuid';

import {makeVarPersisted} from '@renofi/utils';

// Reactive variables
export const [calculatorsVar] = makeVarPersisted([], 'calculators');

// Setters
export const setCalculators = (calculators) => {
  calculatorsVar(calculators);
};

export const addCalculators = (calculator) => {
  calculatorsVar([
    ...calculatorsVar(),
    {
      ...calculator,
      updatedAt: new Date(),
      id: uuid(),
    },
  ]);
};

export const updateCalculator = (calculator) => {
  calculatorsVar(
    calculatorsVar().map((item) =>
      item.id === calculator.id ? {...calculator, updatedAt: new Date()} : item,
    ),
  );
};

export const removeCalculator = (id) => {
  calculatorsVar(calculatorsVar().filter((calc) => calc.id !== id));
};

// Apollo field policy
export const calculatorsPolicy = {
  read() {
    return calculatorsVar();
  },
};

export default {
  calculatorsPolicy: calculatorsPolicy,
  setCalculators: setCalculators,
  updateCalculators: updateCalculator,
  addCalculators: addCalculators,
  removeCalculators: removeCalculator,
};
