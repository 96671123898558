import React from 'react';

import {Image, Text, Flex, Box} from 'rebass';

import {gray} from '@renofi/utils/src/colors';

import charles from '../img/charles.png';

const Card = () => {
  return (
    <Flex>
      <Image mr={20} width={60} height={60} minWidth={60} src={charles} />
      <Box css={{textAlign: 'left'}} pt="5px">
        <Text color={gray}>
          Contact your dedicated RenoFi Account Executive today.
        </Text>
        <Text fontSize={18} color="#212F49">
          Charles Edington
        </Text>
        <Text color={gray}>NMLS # 272063</Text>
      </Box>
    </Flex>
  );
};

export default Card;
