import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import {Link, NavItemWrapper} from './styled';

const NavigationItem = ({title, url}) => {
  const location = useLocation();
  const isSelected = location.pathname.includes(url);

  return (
    <Link to={url}>
      <NavItemWrapper selected={isSelected}>{title}</NavItemWrapper>
    </Link>
  );
};

NavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default NavigationItem;
