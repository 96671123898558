import React from 'react';

import PropTypes from 'prop-types';
import {Document, View, Image, Text as PDFText} from '@react-pdf/renderer';

import {formatDate} from '@renofi/utils/src/date';
import {toCurrency} from '@renofi/utils/src/format';

import {
  Text,
  Container,
  Page,
  HalfPage,
  Strong,
  Line,
  List,
  Footer,
  Logo,
  Card,
} from './styled';
import PdfTable from './PdfTable';

const Pdf = ({nickname, lead, eligibleProducts}) => {
  return (
    <Document title="RenoFi Loans - Eligible Products" author="RenoFi">
      <Page size="A4">
        <View>
          <Container>
            <View>
              <Logo src="https://ucarecdn.com/eb6ab1fa-b3df-47fa-bb29-385ee0a09611/logo.png" />
              <Strong>RenoFi Loans - Eligible Products</Strong>
            </View>
            <Text>
              Date: <Strong>{formatDate(new Date(), {numeric: true})}</Strong>
            </Text>
          </Container>

          <Line />

          <Container>
            <Strong>{nickname ? nickname : 'Scenario'}</Strong>
          </Container>
          <Container>
            <HalfPage>
              <List>
                Property Zip Code: <Strong>{lead.zipCode}</Strong>
              </List>
              <List>
                Estimated Credit Score: <Strong>{lead.creditScore}</Strong>
              </List>
              <List>
                Purchase Price: <Strong>${toCurrency(lead.homeValue)}</Strong>
              </List>
              <List>
                First Mortgage Balance:{' '}
                <Strong>${toCurrency(lead.firstMortgageBalance)}</Strong>
              </List>
            </HalfPage>

            <HalfPage>
              <List>
                Total Monthly Debts:{' '}
                <Strong>${toCurrency(lead.monthlyDebt)}</Strong>
              </List>
              <List>
                Gross Monthl Income:{' '}
                <Strong>${toCurrency(lead.monthlyIncome)}</Strong>
              </List>
              <List>
                After Renovation Home Value:{' '}
                <Strong>${toCurrency(lead.afterRenovationValue)}</Strong>
              </List>
              <List>
                Renovation Loan Amount:{' '}
                <Strong>${toCurrency(lead.loanAmount)}</Strong>
              </List>
            </HalfPage>
          </Container>

          <Line />

          <Container>
            <Strong>Eligible products</Strong>
          </Container>
          <Container>
            <PdfTable eligibleProducts={eligibleProducts} lead={lead} />
          </Container>

          <View style={{height: 16}} />

          <Container>
            <Strong>Speak with your Account Executive at RenoFi</Strong>
          </Container>

          <Card>
            <Image
              style={{width: 36, height: 36, marginRight: 8}}
              src="https://ucarecdn.com/aca96655-b7a0-49dc-8ebf-91b7451a33f0/charles.png"
            />
            <View>
              <Text>Charles Edington</Text>
              <Text>scenarios@renofi.com</Text>
              <Text>(646) 553-2990</Text>
            </View>
          </Card>
        </View>
        <Footer>
          <PDFText style={{fontSize: 9, marginBottom: 10}}>
            <PDFText style={{fontWeight: 800}}>Notice</PDFText>: Eligible
            product loan details are preliminary based on the information
            provided and actual loan details, including loan type, loan amount,
            rate, term, and monthly payments, could change and are determined by
            the lender. This Pre-Application Worksheet is not a
            pre-qualification determination, pre-approval, loan offer, loan
            estimate or credit application decision.
          </PDFText>

          <PDFText style={{fontSize: 9}}>
            RenoFi is a financial technology company that operates an online
            marketplace that allows homeowners to view various financing options
            for residential home renovation projects. RenoFi holds mortgage
            broker licenses (NMLS # 1802847). RenoFi is not a lender. RenoFi
            does not make loans or credit decisions in connection with loans,
            nor does it issue loan commitments or rate lock-in agreements.
          </PDFText>
        </Footer>
      </Page>
    </Document>
  );
};

Pdf.propTypes = {
  nickname: PropTypes.string,
  eligibleProducts: PropTypes.array,
  lead: PropTypes.object,
};

export default Pdf;
