import styled from '@emotion/styled/macro';
import {Text as TextRebass} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Wrapper = styled.div(
  mediaQuery({
    cursor: 'pointer',
    textAlign: ['left', 'center'],
    padding: ['0 20px', '10px 10px 0 10px'],
    minWidth: 120,
    margin: ['15px 0 0 0', '0 0 0 15px'],
    background: 'white',
    borderRadius: 8,
    width: 'auto',
    height: 72,
    border: 'solid 1px rgba(51,51,51,0.1)',
    alignItems: 'center',
    display: ['flex', 'block'],
    lineHeight: '20px',
    ':active': {
      boxShadow: 'inset 0px 0px 5px #00000013',
    },
  }),
);

export const Text = styled(TextRebass)(
  mediaQuery({
    fontSize: 16,
    color: '#163551',
    padding: 'pointer',
    paddingLeft: [20, 0],
  }),
);
