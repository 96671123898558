import {makeVar} from '@apollo/client';

import {calcMaxLoan, calcMonthlyPayment} from '../../utils/calculation';

import {leadVar} from './lead';

// Reactive variables
export const productsVar = makeVar([]);

// Setters
export const setProducts = (products) => {
  productsVar(
    products.map((product) => ({
      ...product,
      calculatedMonthlyPayment: (product.minMonthlyPayment =
        product.refinancedMonthlyMortgagePayments ||
        0 ||
        calcMonthlyPayment(leadVar(), product)),
      calculatedMaxLoanAmount:
        product.maxLoanAmountOffered || calcMaxLoan(leadVar(), product),
    })),
  );
};

// Apollo field policy
export const productsPolicy = {
  read() {
    return productsVar();
  },
};

export default {
  productsPolicy,
  setProducts,
};
