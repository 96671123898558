import styled from '@emotion/styled/macro';
import {Box, Image, Button} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, gray} from '@renofi/utils/src/colors';

import CloseIcon from '../img/close-circle.svg';

export const Wrapper = styled(Box)(
  mediaQuery({
    boxShadow: `-1px 1px 4px 0px ${gray}`,
    position: 'relative',
    display: ['flex', 'none'],
    background: 'white',
    padding: 10,
    width: '100%',
  }),
);

export const Content = styled(Box)(
  mediaQuery({
    textAlign: 'center',
    position: 'relative',
    display: ['flex', 'none'],
    background: darkBlue,
    padding: '10px 40px 10px 10px',
    borderRadius: 100,
    color: 'white',
    height: 70,
    width: '100%',
    flexDirection: 'column',
  }),
);

export const Thumb = styled(Image)(
  mediaQuery({
    width: 60,
    height: 60,
    position: 'absolute',
    right: 5,
    top: 5,
  }),
);

export const CloseButton = styled(Button)({
  width: 60,
  height: 60,
  position: 'absolute',
  right: 5,
  top: 5,
  borderRadius: 100,
  background: `url(${CloseIcon})`,
});
