import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {Text, Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {size, filter, omit, isNil} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {useEligibleProducts} from '@renofi/api';
import {
  CalculatorIcon,
  InfoSolidIcon,
  RenofiHouseIcon,
  toast,
  Toggle,
  ProductsTable,
} from '@renofi/components';
import {filterEmptyValues, mapObjectValues} from '@renofi/utils';

import {
  addCalculators,
  initialLead,
  leadVar,
  productsVar,
  calculatorsVar,
  setLead,
  setProducts,
  updateCalculator,
} from '../api/cache';
import {onEligibleProducts} from '../api';
import {Panel, PanelBody, PanelHeader, Small, TitleBar} from '../components';
import Contact from '../Contact';
import {Container} from '../App';
import NicknameModal from '../NicknameModal';

import ActionButtons from './ActionButtons';
import PropertyDetails from './PropertyDetails';
import PanelTitle from './PanelTitle';

const Calculator = ({onInfoClick}) => {
  const {id} = useParams();
  const history = useHistory();
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [calculator, setCalculator] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const lead = useReactiveVar(leadVar);
  const calculators = useReactiveVar(calculatorsVar);
  const eligibleProducts = useReactiveVar(productsVar);
  const fetchEligibleProducts = useEligibleProducts({
    onCompleted: onEligibleProducts,
  });
  const hasProducts = Boolean(eligibleProducts?.length);
  const isNewCalculator = id === 'new';

  useEffect(() => {
    const calculator = calculators.find((s) => s.id === id) || {};
    setCalculator(calculator);
    setLead(isNewCalculator ? initialLead : calculator.lead);
    setProducts(isNewCalculator ? [] : calculator.eligibleProducts);
  }, [calculators, id]);

  useEffect(() => sendEvent('MLO/Calculator-Page', {isNewCalculator}), []);

  function validateForm(data) {
    const invalidValues = filterEmptyValues(data);
    const err = mapObjectValues(invalidValues, () => true);
    setErrors(err);
  }

  function isFormValid(data) {
    const validValues = filter(data, (item) => {
      return !isNil(item) && item !== '';
    });
    return size(validValues) >= size(data);
  }

  function onChange(key, value, err) {
    setLead({...lead, [key]: value});
    setErrors({...errors, [key]: err});
  }

  async function onSubmit() {
    sendEvent('MLO/Product-Form-Submit', lead);

    if (!isFormValid(lead) || loading) {
      return validateForm(lead);
    }
    setLoading(true);
    await fetchEligibleProducts({
      criteria: omit(lead, ['zipCode', 'renovationCost']),
      zipCode: String(lead.zipCode),
      onlyRenofiProducts: true,
    });

    sendEvent('MLO/Product-Form-Submit-Success');
    setDirty(true);
    setLoading(false);
  }

  function onSaveClick() {
    sendEvent('MLO/Save-Scenario-Click', {isNewCalculator});

    if (isNewCalculator) {
      setShowSaveModal(true);
    } else {
      onSave();
    }
  }

  function onSave(nickname) {
    const data = {eligibleProducts, lead};
    if (isNewCalculator) {
      addCalculators({eligibleProducts, lead, nickname});
      toast.success('Calculator added');
      history.push('/calculators');
    } else {
      updateCalculator({
        ...data,
        id,
        nickname: nickname || calculator.nickname,
      });
      toast.success('Calculator saved');
      setShowSaveModal(false);
    }
  }

  return (
    <>
      <TitleBar
        showBackButton={calculators?.length > 0}
        title={
          <PanelTitle
            isNewCalculator={isNewCalculator}
            calculator={calculator}
            onEdit={() => setShowSaveModal(true)}
          />
        }
      />

      <Container>
        <Flex flexDirection="column" width={1}>
          <Panel mt={[0, 33]}>
            <PanelHeader icon={CalculatorIcon}>
              <Flex justifyContent="space-between" css={{width: '100%'}}>
                <Text>RenoFi Loan Calculator</Text>
                <InfoSolidIcon onClick={() => onInfoClick(true)} />
              </Flex>
            </PanelHeader>
            <PanelBody>
              <PropertyDetails
                onChange={onChange}
                onSubmit={onSubmit}
                formData={lead}
                errors={errors}
                loading={loading}
              />
            </PanelBody>
            <PanelHeader
              middle
              icon={RenofiHouseIcon}
              css={{borderBottom: 'none'}}>
              <Text>Eligible products</Text>
            </PanelHeader>

            <Toggle show={!hasProducts}>
              <PanelBody css={{height: [100, 'auto'], alignItems: 'center'}}>
                <Small css={{textAlign: 'center'}} width={1}>
                  <Toggle show={!dirty}>
                    Enter details in section above and click "Show eligible
                    products"
                  </Toggle>
                  <Toggle show={dirty}>
                    Sorry, we couldn&apos;t find any products for your search!
                  </Toggle>
                </Small>
              </PanelBody>
            </Toggle>

            <Toggle show={hasProducts}>
              <PanelBody css={{padding: 0, flexDirection: 'column'}}>
                <ProductsTable eligibleProducts={eligibleProducts} />

                <ActionButtons
                  nickname={calculator?.nickname}
                  onSaveClick={onSaveClick}
                  lead={lead}
                  eligibleProducts={eligibleProducts}
                />
              </PanelBody>
            </Toggle>
          </Panel>

          <Contact />
        </Flex>
      </Container>

      <NicknameModal
        value={calculator?.nickname}
        show={showSaveModal}
        onSave={onSave}
        onClose={() => setShowSaveModal(false)}
      />
    </>
  );
};

Calculator.propTypes = {
  onInfoClick: PropTypes.func.isRequired,
};

export default Calculator;
