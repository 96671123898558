import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {EmailPanel} from '@renofi/modules';

import Button from '../Button';
import EmailIcon from '../img/email-icon.svg';
import MobileIcon from '../img/mobile-icon.svg';
import Card from '../Card';

import {CloseButton} from './styled';

const ContactDesktop = ({onSchedule}) => {
  const [modal, setModal] = useState(false);

  function onClose(e) {
    e.stopPropagation();
    setModal(false);
  }

  return (
    <Box
      justifyContent="space-between"
      css={mediaQuery({
        flexDirection: ['column', 'row'],
        display: ['none', 'flex'],
      })}>
      <Card />

      <Flex>
        <Box css={{position: 'relative'}}>
          {modal ? (
            <>
              <CloseButton onClick={onClose} />
              <EmailPanel
                width={['100%', 'auto']}
                shortName="Charles"
                showCard={false}
                email="scenarios@renofi.com"
                css={{
                  position: 'absolute',
                  bottom: `calc(100% + 12px)`,
                  left: -120,
                  width: 404,
                  zIndex: 101,
                }}
              />
            </>
          ) : (
            <Button
              icon={EmailIcon}
              text="Email"
              onClick={() => setModal(true)}
            />
          )}
        </Box>

        <Button icon={MobileIcon} text="Schedule a call" onClick={onSchedule} />
      </Flex>
    </Box>
  );
};

ContactDesktop.propTypes = {
  onSchedule: PropTypes.func,
};

export default ContactDesktop;
