import styled from '@emotion/styled/macro';
import {Box} from 'rebass';
import {Link as RouterLink} from 'react-router-dom';

import {mediaQuery} from '@renofi/utils';
import {darkYellow} from '@renofi/utils/src/colors';

export const Grid = styled(Box)(({columns}) =>
  mediaQuery({
    width: '100%',
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: ['100%', columns || '1fr 1fr 1fr 1fr'],
  }),
);

export const Link = styled(RouterLink)(() =>
  mediaQuery({
    color: darkYellow,
    fontSize: 16,
  }),
);
