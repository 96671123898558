import React from 'react';

import {Box} from 'rebass';
import {FixedBottom} from 'react-fixed-bottom';
import {useHistory} from 'react-router-dom';

import {Panel, Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import ContactDesktop from './Desktop';
import ContactMobile from './Mobile';

const Contact = () => {
  const {isDesktop, isMobile} = useScreenSize();
  const history = useHistory();

  function showOfficerFormModal() {
    sendEvent('MLO/Schedule-Button-Click');
    history.push('/signup');
  }

  return (
    <>
      <Toggle show={isDesktop}>
        <Panel
          title="Speak with your Account Executive at RenoFi"
          background="#FBFBFB"
          css={{
            boxShadow: 'none !important',
            border: 'solid 1px #E5E5E5',
          }}>
          <ContactDesktop onSchedule={showOfficerFormModal} />
        </Panel>
      </Toggle>

      <Toggle show={isMobile}>
        <Box height={40}></Box>
        <FixedBottom>
          <ContactMobile onSchedule={showOfficerFormModal} />
        </FixedBottom>
      </Toggle>
    </>
  );
};

export default Contact;
