import {useQuery} from '@apollo/client';

import {LICENSES} from '../queries/licenses';

export default function useGetLicences() {
  const {refetch} = useQuery(LICENSES, {skip: true});
  const imperativelyCallQuery = () => {
    return refetch();
  };
  return imperativelyCallQuery;
}
